import { useState, useEffect } from 'react';
import { Zap } from 'lucide-react';

interface TokenBoost {
  url: string;
  chainId: string;
  tokenAddress: string;
  amount: number;
  icon?: string;
  description?: string;
  name?: string;
  symbol?: string;
}

export const TopCoins = () => {
  const [tokens, setTokens] = useState<TokenBoost[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBoostedTokens = async () => {
      try {
        const response = await fetch('https://api.dexscreener.com/token-boosts/latest/v1');
        if (!response.ok) return;
        const data = await response.json();
        console.log('Raw token data:', data);
        
        if (Array.isArray(data)) {
          // Take the first 3 tokens that have a tokenAddress
          const validTokens = data
            .filter(token => token?.tokenAddress)
            .slice(0, 3)
            .map(token => ({
              url: token.url || '',
              chainId: token.chainId || '',
              tokenAddress: token.tokenAddress,
              amount: token.amount || 1,
              icon: token.icon || '',
              description: token.description || '',
              name: token.name || '',
              symbol: token.symbol || token.tokenAddress.slice(0, 6)
            }));
          
          console.log('Processed tokens:', validTokens);
          setTokens(validTokens);
        }
      } catch (err) {
        console.error('Error fetching tokens:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchBoostedTokens();
    const interval = setInterval(fetchBoostedTokens, 30000);
    return () => clearInterval(interval);
  }, []);

  const formatChainName = (chainId: string) => {
    const chains: Record<string, string> = {
      'ethereum': 'ETH',
      'bsc': 'BSC',
      'polygon': 'MATIC',
      'arbitrum': 'ARB',
      'optimism': 'OP',
      'base': 'BASE',
      'solana': 'SOL'
    };
    return chains[chainId.toLowerCase()] || chainId;
  };

  if (loading) {
    return (
      <div className="w-full py-2 bg-[#2a1a4a]/80 border-y border-[#FF69B4]/30">
        <p className="text-[#00FFFF] text-center animate-pulse">Loading...</p>
      </div>
    );
  }

  if (tokens.length === 0) {
    return (
      <div className="w-full py-2 bg-[#2a1a4a]/80 border-y border-[#FF69B4]/30">
        <div className="flex items-center justify-center gap-2">
          <Zap className="w-4 h-4 text-[#FFD700]" />
          <span className="text-[#00FFFF] text-sm">Latest Boosts</span>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full py-2 bg-[#2a1a4a]/80 border-y border-[#FF69B4]/30">
      <div className="flex items-center min-h-[32px]">
        <div className="flex-shrink-0 px-4 flex items-center gap-2 bg-[#3a1a5a] py-1 rounded-r-full">
          <Zap className="w-4 h-4 text-[#FFD700]" />
          <span className="text-[#00FFFF] text-sm font-bold">Latest Boosts</span>
        </div>
        <div className="flex-1 overflow-hidden ml-4">
          <div className="ticker whitespace-nowrap">
            {[...tokens, ...tokens].map((token, index) => (
              <span key={index} className="inline-flex items-center mx-4">
                {token.icon ? (
                  <img 
                    src={token.icon}
                    alt=""
                    className="w-4 h-4 mr-2 rounded-full bg-[#FFD700]/10"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.style.display = 'none';
                    }}
                  />
                ) : (
                  <span className="text-[#FF69B4] mr-2">•</span>
                )}
                <span className="text-[#FFD700]">
                  {token.symbol || token.name || token.tokenAddress.slice(0, 6)}
                </span>
                <span className="text-[#00FFFF] ml-1">
                  [{formatChainName(token.chainId)}]
                </span>
                {token.amount > 0 && (
                  <span className="text-[#FF69B4] ml-2">
                    Boost: {Math.round(token.amount)}x
                  </span>
                )}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
