import { useState, useRef, useEffect } from 'react';
import { ChatMessage } from '../types';
import { sendMessage } from '../services/api';

interface LeaderboardEntry {
  name: string;
  completionTime: number;
  trades: number;
}

interface TradingState {
  portfolioViewed: boolean;
  marketScanned: boolean;
  strategyViewed: boolean;
  startTime?: number;
  walletAddress?: string;
}

const WELCOME_MESSAGE = `🚀 Ready to find the next 1000x gem? Let's get started! 🐰💎`;

// Trading Commands
const MEMESCAN_TRIGGER = '/memescan';
const PORTFOLIO_TRIGGER = '/portfolio';
const MARKET_TRIGGER = '/market';
const STRATEGY_TRIGGER = '/strategy';

const MEMESCAN_RESPONSE = `🔍 INITIATING MEME COIN SCAN...
██████████████████████ 100%

🔥 HOT MEMES DETECTED:
1. $PEPE variants still trending
2. AI tokens gaining momentum
3. New rabbit-themed coins emerging

Want to dive deeper? Ask me about any of these trends! 🚀`;

const PORTFOLIO_RESPONSE = `💼 DEGEN PORTFOLIO TRACKER

Current Holdings:
- 70% Blue-chip memes
- 20% Micro-cap gems
- 10% Moon shots

Risk Level: MAXIMUM DEGEN 🎰
Moon Status: IMMINENT 🌕

Need advice on portfolio rebalancing? Just ask! 💎🙌`;

const MARKET_RESPONSE = `📊 CURRENT MARKET VIBE CHECK

Meme Momentum: 🔥🔥🔥
Fear & Greed: EXTREME GREED
Paper Hand Risk: LOW

Top Trending:
#memecoin #degenfi #rabbitseason

Want more detailed analysis? Let me know! 📈`;

const STRATEGY_RESPONSE = `🎯 DEGEN TRADING STRATEGY

Rule #1: Buy the memes
Rule #2: Hold the memes
Rule #3: Never sell (except to buy better memes)

Pro Tips:
- Follow crypto influencers for alpha
- Join degen Telegram groups
- Always DYOR (Do Your Own Research)

Ready for some serious gains? 🚀🌕`;

export function useChat() {
  const [messages, setMessages] = useState<ChatMessage[]>([
    {
      role: 'assistant',
      content: WELCOME_MESSAGE
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [tradingState, setTradingState] = useState<TradingState>(() => {
    const saved = localStorage.getItem('tradingState');
    return saved ? JSON.parse(saved) : {
      portfolioViewed: false,
      marketScanned: false,
      strategyViewed: false
    };
  });

  useEffect(() => {
    localStorage.setItem('tradingState', JSON.stringify(tradingState));
  }, [tradingState]);

  const handleSpecialCommands = (message: string): boolean => {
    if (message.toLowerCase() === MEMESCAN_TRIGGER) {
      setMessages((prev: ChatMessage[]) => [...prev, 
        { role: 'user', content: message },
        { role: 'assistant', content: MEMESCAN_RESPONSE }
      ]);
      setTradingState((prev: TradingState) => ({ ...prev, marketScanned: true }));
      return true;
    }

    if (message.toLowerCase() === PORTFOLIO_TRIGGER) {
      setMessages((prev: ChatMessage[]) => [...prev,
        { role: 'user', content: message },
        { role: 'assistant', content: PORTFOLIO_RESPONSE }
      ]);
      setTradingState((prev: TradingState) => ({ ...prev, portfolioViewed: true }));
      return true;
    }

    if (message.toLowerCase() === MARKET_TRIGGER) {
      setMessages((prev: ChatMessage[]) => [...prev,
        { role: 'user', content: message },
        { role: 'assistant', content: MARKET_RESPONSE }
      ]);
      setTradingState((prev: TradingState) => ({ ...prev, marketScanned: true }));
      return true;
    }

    if (message.toLowerCase() === STRATEGY_TRIGGER) {
      setMessages((prev: ChatMessage[]) => [...prev,
        { role: 'user', content: message },
        { role: 'assistant', content: STRATEGY_RESPONSE }
      ]);
      setTradingState((prev: TradingState) => ({ ...prev, strategyViewed: true }));
      return true;
    }

    return false;
  };

  const handleSubmit = async (message: string) => {
    if (!message.trim()) return;

    if (handleSpecialCommands(message)) {
      return;
    }

    const newMessage: ChatMessage = { role: 'user', content: message };
    setMessages((prev: ChatMessage[]) => [...prev, newMessage]);
    setIsLoading(true);

    try {
      const response = await sendMessage([...messages, newMessage]);
      setMessages((prev: ChatMessage[]) => [...prev, {
        role: 'assistant',
        content: response
      }]);
    } catch (error) {
      console.error('Error:', error);
      setMessages((prev: ChatMessage[]) => [...prev, {
        role: 'assistant',
        content: '❌ Oops! Something went wrong with the trading bot. Please try again! 🔄'
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    messages,
    isLoading,
    handleSubmit,
    messagesEndRef,
    tradingState
  };
}