import { ChatMessage } from '../types';

export async function sendMessage(messages: ChatMessage[]): Promise<string> {
  try {
    const response = await fetch('/.netlify/functions/chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ messages })
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to get response');
    }

    const data = await response.json();
    return data.message;

  } catch (error: any) {
    console.error('Chat API Error:', error);
    throw new Error(error.message || 'Failed to get response from AI');
  }
}