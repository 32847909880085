import React, { RefObject } from 'react';
import { ChatMessage } from '../types';
import ReactMarkdown from 'react-markdown';
import Typewriter from 'typewriter-effect';

interface MessageListProps {
  messages: ChatMessage[];
  messagesEndRef: RefObject<HTMLDivElement>;
}

const ASCII_ART = {
  welcome: `🚀 Welcome to Olive AI! 🐰`,
  error: `
    ⚠️  MARKET VOLATILITY DETECTED  ⚠️
    
    📉 TEMPORARY TRADING INTERRUPTION 📉
    
    🔄 Please try again shortly... 🔄`
};

const getMoodClass = (content: string): string => {
  const lowerContent = content.toLowerCase();
  if (lowerContent.includes('error') || lowerContent.includes('oops') || lowerContent.includes('wrong')) {
    return 'text-[#FF0000] [text-shadow:_0_0_5px_#ff0000]';
  }
  if (lowerContent.includes('warning') || lowerContent.includes('caution') || lowerContent.includes('risk')) {
    return 'text-[#FFD700] [text-shadow:_0_0_5px_#ffd700]';
  }
  if (lowerContent.includes('moon') || lowerContent.includes('gain') || lowerContent.includes('profit')) {
    return 'text-[#00FFFF] [text-shadow:_0_0_8px_#00ffff]';
  }
  return 'text-[#FF69B4] [text-shadow:_0_0_5px_#ff69b4]';
};

export const MessageList: React.FC<MessageListProps> = ({ messages, messagesEndRef }) => {
  return (
    <div className="space-y-2 font-mono text-sm">
      {messages.map((message, index) => (
        <div key={index} className="space-y-1">
          <div className="flex items-center gap-2">
            <span className="text-[#FF69B4] opacity-90 font-bold text-lg">
              {message.role === 'user' ? '> USER' : '[OLIVE]'}
            </span>
            <span className="text-[#00FFFF]">:</span>
          </div>
          <div className={`pl-4 ${
            message.role === 'user' 
              ? 'text-[#FFD700] text-lg' 
              : 'text-[#FF69B4] text-lg'
          }`}>
            {index === 0 && message.role === 'assistant' ? (
              <pre className="text-lg text-green-500 mb-2 font-bold">{ASCII_ART.welcome}</pre>
            ) : null}
            {message.content.includes('ERROR') && message.role === 'assistant' ? (
              <pre className="text-xs text-red-500 mb-2 font-bold">{ASCII_ART.error}</pre>
            ) : null}
            {message.role === 'assistant' ? (
              <div className="prose prose-invert max-w-none prose-p:my-1 prose-pre:my-1 prose-p:text-green-400 prose-p:[text-shadow:_0_0_5px_#00ff00]">
                <Typewriter
                  options={{
                    strings: [message.content],
                    autoStart: true,
                    delay: 30,
                    cursor: '_',
                    loop: false,
                    deleteSpeed: Infinity,
                    wrapperClassName: "inline-block"
                  }}
                />
              </div>
            ) : (
              <ReactMarkdown className="prose prose-invert max-w-none prose-p:my-1 prose-pre:my-1">
                {message.content}
              </ReactMarkdown>
            )}
          </div>
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};