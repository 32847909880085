import React, { useState } from 'react';
import { Send } from 'lucide-react';

interface InputFormProps {
  onSubmit: (input: string) => void;
  isLoading: boolean;
}

export const InputForm: React.FC<InputFormProps> = ({ onSubmit, isLoading }) => {
  const [input, setInput] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;
    onSubmit(input);
    setInput('');
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!input.trim()) return;
      onSubmit(input);
      setInput('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="relative">
      <div className="flex items-center gap-2 bg-[#2a1a4a]/90 backdrop-blur-sm rounded-lg p-2 shadow-lg shadow-[#FF69B4]/20 border-2 border-[#FF69B4]/50">
        <span className="text-[#00FFFF] font-bold animate-pulse">{'>'}</span>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
          className="flex-1 bg-transparent border-none outline-none text-[#FFD700] font-medium tracking-wide placeholder-[#FF69B4]/70"
          placeholder={isLoading ? '🔄 Analyzing market...' : '🚀 Enter your message...'}
        />
        <button
          type="submit"
          disabled={isLoading}
          className="p-2 hover:bg-[#FF69B4]/30 rounded transition-colors disabled:opacity-50"
        >
          <Send className="w-5 h-5 text-[#FF69B4]" />
        </button>
      </div>
    </form>
  );
};