import { Rabbit, Copy, Trophy } from 'lucide-react';
import { MessageList } from './components/MessageList';
import { InputForm } from './components/InputForm';
import { TopCoins } from './components/TopCoins';
import { useChat } from './hooks/useChat';
import { useState, useEffect } from 'react';
import { type ReactElement } from 'react';

const App = (): ReactElement => {
  const { messages, isLoading, handleSubmit, messagesEndRef, tradingState } = useChat();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen text-[#FF69B4] p-4 font-mono relative min-w-full">
      {/* Base CRT effect */}
      <div className="crt fixed inset-0 pointer-events-none opacity-30"></div>
      
      {/* Smooth scan line */}
      <div className="pointer-events-none fixed inset-0 bg-[linear-gradient(transparent_0%,rgba(255,105,180,0.1)_50%,transparent_100%)] animate-crt-overlay"></div>
      
      {/* Subtle ambient glow */}
      <div className="fixed inset-0 pointer-events-none bg-[radial-gradient(circle_at_50%_50%,rgba(0,255,255,0.15),transparent_100%)] opacity-50"></div>

      <div className="max-w-4xl mx-auto relative">
        {/* Left side rabbits */}
        <div className="absolute top-24 -left-16 w-32 h-32 animate-float-1">
          <img src="rabbit01.png" alt="rabbit" className="w-full h-full" loading="lazy" />
        </div>
        <div className="absolute top-96 -left-16 w-32 h-32 animate-float-2">
          <img src="rabbit01.png" alt="rabbit" className="w-full h-full" loading="lazy" />
        </div>

        {/* Right side rabbits */}
        <div className="absolute top-24 -right-16 w-32 h-32 animate-float-3">
          <img src="rabbit01.png" alt="rabbit" className="w-full h-full" loading="lazy" />
        </div>
        <div className="absolute top-96 -right-16 w-32 h-32 animate-float-4">
          <img src="rabbit01.png" alt="rabbit" className="w-full h-full" loading="lazy" />
        </div>

        {/* Center rabbit between chatbot and roadmap */}
        <div className="absolute -bottom-8 left-1/2 w-32 h-32 animate-float-5 -translate-x-1/2">
          <img src="rabbit01.png" alt="rabbit" className="w-full h-full" loading="lazy" />
        </div>

        {/* Header Message */}
        <div className="mb-4 text-center relative">
          <div className="inline-block bg-[#2a1a4a]/80 border border-[#FF69B4]/50 rounded-lg px-4 py-2 shadow-lg shadow-[#FF69B4]/20">
            <p className="text-[#FF69B4] font-mono text-lg animate-textShadow">
              <span className="text-[#00FFFF] font-bold text-xl">[ OLIVE_AI_v2.0 ]:</span> Ready to help you find the next 1000x gem! 🚀🐰💎
              <span className="ml-2">
                <a
                  href="https://x.com/olivebunnyAI"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#FF69B4] hover:text-[#00FFFF] transition-colors duration-300 mx-1"
                >
                  𝕏
                </a>
                <a
                  href="https://t.me/oliveaiportal"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#FF69B4] hover:text-[#00FFFF] transition-colors duration-300 mx-1"
                >
                  📱
                </a>
              </span>
            </p>
            <div className="mt-2 flex items-center justify-center space-x-2">
              <span className="text-[#00FFFF] font-bold">CA:</span>
              <div className="relative group">
                <div 
                  className="bg-[#3a1a5a]/60 px-3 py-1 rounded border border-[#FF69B4]/30 hover:border-[#FF69B4]/50 cursor-pointer transition-all duration-300 font-mono text-sm"
                  onClick={() => {
                    navigator.clipboard.writeText("7rq9ExGpttv5SXF6iAPm99VsL8WEM3CiJ8RRVRDEpump");
                    // You might want to add a toast notification here
                  }}
                >
                  <span className="text-[#FF69B4]">7rq9ExGpttv5SXF6iAPm99VsL8WEM3CiJ8RRVRDEpump</span>
                  <span className="ml-2 text-[#00FFFF] opacity-0 group-hover:opacity-100 transition-opacity duration-300">📋</span>
                </div>
                <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-[#2a1a4a] text-[#00FFFF] px-2 py-1 rounded text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                  Click to copy
                </div>
              </div>
            </div>
          </div>
          {/* Meme Rabbit Image */}
          <div className="absolute -right-20 -top-10 w-32 h-32 animate-rabbit-hop">
            <img 
              src="/rabbit01.png" 
              alt="Meme Rabbit" 
              className="w-full h-full object-contain transform -rotate-12"
            />
          </div>
        </div>

        <div className="mt-4">
          {/* Terminal window with chat */}
          <div className="relative rounded-lg overflow-hidden border-2 border-[#FF69B4]/50 shadow-xl shadow-[#FF69B4]/30">
            <div className="absolute inset-0">
              <div 
                className="absolute inset-0 bg-cover bg-center opacity-40 glitch-bg mix-blend-luminosity"
                style={{ backgroundImage: 'url("/rabbit01.png")' }}
              />
              <div className="absolute inset-0 bg-gradient-to-br from-[#2a1a4a]/90 via-[#3a1a5a]/80 to-[#4a1a6a]/70 mix-blend-overlay" />
            </div>
            
            <div className="relative">
              <main className="p-4 space-y-4 h-[600px] flex flex-col">
                <div className="flex-1 overflow-y-auto custom-scrollbar">
                  <MessageList messages={messages} messagesEndRef={messagesEndRef} />
                  <div ref={messagesEndRef} />
                </div>
                <InputForm onSubmit={handleSubmit} isLoading={isLoading} />
              </main>
            </div>
          </div>
        </div>

        {/* Top Coins Section */}
        <TopCoins />

        {/* Roadmap section */}
        <div className="mt-12">
          <h2 className="text-[#00FFFF] text-2xl font-bold mb-8 text-center">
            Development Roadmap
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Phase 1 */}
            <div className="bg-[#3a1a5a]/60 border border-[#FF69B4]/30 rounded-lg p-6 hover:border-[#FF69B4]/50 transition-all duration-300">
              <h3 className="text-[#00FFFF] text-xl font-bold mb-4">Phase 1: Infrastructure</h3>
              <ul className="text-[#FFD700] space-y-2">
                <li className="flex items-center">
                  <span className="text-lg mr-2">🔍</span>
                  Market Intelligence
                </li>
                <li className="flex items-center">
                  <span className="text-lg mr-2">💧</span>
                  Real-time liquidity monitoring
                </li>
                <li className="flex items-center">
                  <span className="text-lg mr-2">🔗</span>
                  Multi-chain connectivity
                </li>
              </ul>
            </div>

            {/* Phase 2 */}
            <div className="bg-[#3a1a5a]/60 border border-[#FF69B4]/30 rounded-lg p-6 hover:border-[#FF69B4]/50 transition-all duration-300">
              <h3 className="text-[#00FFFF] text-xl font-bold mb-4">Phase 2: Analytics</h3>
              <ul className="text-[#FFD700] space-y-2">
                <li className="flex items-center">
                  <span className="text-lg mr-2">🛡️</span>
                  Smart contract security verification
                </li>
                <li className="flex items-center">
                  <span className="text-lg mr-2">🐋</span>
                  Whale wallet tracking (&gt;$100k)
                </li>
                <li className="flex items-center">
                  <span className="text-lg mr-2">📊</span>
                  Token holder distribution
                </li>
              </ul>
            </div>

            {/* Phase 3 */}
            <div className="bg-[#3a1a5a]/60 border border-[#FF69B4]/30 rounded-lg p-6 hover:border-[#FF69B4]/50 transition-all duration-300">
              <h3 className="text-[#00FFFF] text-xl font-bold mb-4">Phase 3: Trading Core</h3>
              <ul className="text-[#FFD700] space-y-2">
                <li className="flex items-center">
                  <span className="text-lg mr-2">📈</span>
                  Position management
                </li>
                <li className="flex items-center">
                  <span className="text-lg mr-2">⚡</span>
                  Risk parameters & limits
                </li>
                <li className="flex items-center">
                  <span className="text-lg mr-2">🛡️</span>
                  MEV-protected execution
                </li>
              </ul>
            </div>

            {/* Phase 4 */}
            <div className="bg-[#3a1a5a]/60 border border-[#FF69B4]/30 rounded-lg p-6 hover:border-[#FF69B4]/50 transition-all duration-300">
              <h3 className="text-[#00FFFF] text-xl font-bold mb-4">Phase 4: Advanced Tools</h3>
              <ul className="text-[#FFD700] space-y-2">
                <li className="flex items-center">
                  <span className="text-lg mr-2">🔄</span>
                  Strategy backtesting
                </li>
                <li className="flex items-center">
                  <span className="text-lg mr-2">📊</span>
                  Custom indicators
                </li>
                <li className="flex items-center">
                  <span className="text-lg mr-2">💰</span>
                  Portfolio analytics & PnL
                </li>
              </ul>
            </div>
          </div>

          {/* Coming Soon Banner */}
          <div className="mt-8 text-center">
            <p className="text-[#00FFFF] text-lg animate-pulse">
              More exciting features coming soon! 🚀
            </p>
            
            {/* Social Links */}
            <div className="mt-6 flex justify-center space-x-6">
              <a
                href="https://x.com/olivebunnyAI"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#FF69B4] hover:text-[#00FFFF] transition-colors duration-300"
              >
                𝕏 Twitter
              </a>
              <a
                href="https://t.me/oliveaiportal"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#FF69B4] hover:text-[#00FFFF] transition-colors duration-300"
              >
                📱 Telegram
              </a>
              <a
                href="https://www.instagram.com/and_olive_"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#FF69B4] hover:text-[#00FFFF] transition-colors duration-300"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;